import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Heading, HStack, Text } from "@chakra-ui/react";
import Tech from "../components/Tech";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import ContactForm from "../components/ContactForm";
import Container from "../components/ui/Container";

function ThirdPartyPage() {
  return (
    <Layout>
      <Seo
        title="BITDECODERS | THIRD-PARTY INTEGRATION DEVELOPMENT COMPANY"
        meta={[
          {
            name: "keywords",
            content:
              "website development company, website developer near me, website designing company, ecommerce website developer, web developer companies, indian web design company, website design and development company, web development agency, best web development company, web design and development services, ecommerce website development company, web development company near me, ecommerce web development company, ecommerce web design company, custom web design services, website development company near me, web design and development company, best web designer, top website development company, custom web development company, best website developers, web design & development company, website development agency, webdesign company, best ecommerce website development company, business website development, web and mobile app development companies, web design & development services, ecommerce website designing company, top web design company, website design & development company, mobile app designing company, best web designing companies,  best indian web design company, web development companies services",
          },
          {
            name: "description",
            content:
              "We specialize in providing website designing and development and third party integration services including solution integration and offshore IT outsourcing to small and medium-sized businesses (SMB) across the globe. Go with us and you can benefit from our experience, with an amazing range of skills, deep vertical industries expertise, and excellence in top technologies, like Html, Html5, Node JS, React JS, Next JS, Gatsby, Swift, Kotlin, Flutter, Data Base Management, and various other custom application fields.",
          },
        ]}
      />

      <Box position="relative">
        <StaticImage
          src="../images/third-party-header.png"
          alt="Bitdecoders Third-party banner image"
          layout="fullWidth"
        />
        <Heading
          as="h1"
          fontSize={{ base: "lg", md: "3xl", lg: "5xl" }}
          fontWeight="bold"
          p={{ base: "4", md: "6", lg: "8" }}
          position="absolute"
          left="0"
          bottom={{ base: "0.5", md: "4", lg: "10" }}
        >
          <HStack>
            <Text color="red.500">THIRD PARTY</Text>
            <Text color="white">INTEGRATION</Text>
          </HStack>
          <Box fontSize={{ base: "xs", md: "sm", lg: "md" }} fontWeight="bold">
            <Text color="white">
              Let&apos;s talk about what we can build together
            </Text>
          </Box>
        </Heading>
      </Box>

      <Container fluid>
        <div className="grid grid-cols-1 md:grid-cols-2 mx-4">
          <div className="mx-10 mt-6 text-lg space-y-6">
            <div>
              <p>
                We specialize to be connected to as many other applications as
                possible through integrations. Instead of building every
                integration itself. Website integration is when your website
                sends or receives information from another website or
                application.
              </p>
            </div>

            <div>
              <p>
                We are committed to providing the best{" "}
                <span className="font-bold">Third-party Integration (API)</span>{" "}
                services including solution integration and offshore IT
                outsourcing to small and medium-sized businesses (SMB) across
                the globe. Go with us and you can benefit from our experience,
                with an amazing range of skills, deep vertical industries
                expertise, and excellence in top technologies, like{" "}
                <span className="font-bold">
                  Html, Html5, Node JS, React JS, Next JS, Gatsby, Swift,
                  Kotlin, Flutter with Data Base Management, and various other
                  custom application fields.
                </span>
              </p>
            </div>
          </div>

          <div className="relative mt-8 md:w-3/4 md:-top-36 md:left-10">
            <ContactForm />
          </div>
        </div>

        <Tech />
      </Container>
    </Layout>
  );
}

export default ThirdPartyPage;
